
import {
  BackingViewModel,
  DeliveryTypeViewModel,
  DeliveryZoneViewModel,
  FixingViewModel,
  FrameViewModel,
  GlassViewModel,
  LaminationViewModel,
  MouldingViewModel,
  PrintMediumViewModel,
  RetouchingViewModel,
  SizeViewModel,
  SpecialOrderItemViewModel,
  SpecialOrderViewModel,
  StationeryViewModel,
  SubstrateColourViewModel,
  SubstrateViewModel,
  WindowMountViewModel,
  PaymentDataModel,
} from "@/core/types/specialOrderViewModelRequest";
import { getCurrencySymbol } from "@/core/utils";
import { defineComponent, Ref, ref } from "vue";
import { Notification } from "@progress/kendo-popups-vue-wrapper";
import {
  GetQCUserListViewModel,
  UserGetQCUserListViewModel,
} from "@/core/types/user/getQCUserListViewModel";
import PreventUnpaidStatusSelectModalVue from "@/components/Modal/PreventUnpaidStatusSelectModal.vue";
import PreventUnpaidStatusSelectModalResponse from "@/core/types/modal/preventUnpaidStatusSelectModalResponse";
import {DatePicker} from "@progress/kendo-vue-dateinputs";

declare var $: any;
export default defineComponent({
  name: "CreateOrder",
  components: {
    datepicker: DatePicker,
    KNotification: Notification,
    PreventUnpaidStatusSelectModalVue: PreventUnpaidStatusSelectModalVue,
  },
  setup() {
    const PopupNotification: Ref<any> = ref(null);
    const discount: any = ref(0);
    const priceExVat: Ref<number> = ref(0.0);
    const customerId: Ref<string | null> = ref(null);
    const quantityArray: Ref<number[]> = ref([]);
    const deliveryBranches: Ref<{ Id: number; Name: string }[]> = ref([]);
    const orderItemTypes: Ref<{ name: string; value: string }[]> = ref([]);
    const orderItemFreeTextTypes: Ref<{ name: string; value: string }[]> = ref(
      [],
    );
    const currency: Ref<string | null> = ref(null);
    const emailQuoteTo: Ref<string> = ref("");
    const customerReference: Ref<string> = ref("");
    const isSubmitedOrder: Ref<boolean | null> = ref(false);
    const countries: Ref<
      {
        value: number;
        name: string;
        IsUKForVAT: boolean;
        IsEU: boolean;
        PostageDeliveryBranchId: number;
        CourierDeliveryBranchId: number;
      }[]
    > = ref([]);
    const vatRates: Ref<{ BranchId: number; Id: number; Rate: number }[]> = ref(
      [],
    );
    const vatRate: Ref<number | null> = ref(null);
    const specialOrderItems: Ref<SpecialOrderItemViewModel[]> = ref([]);
    const specialOrder: Ref<SpecialOrderViewModel> = ref({
      Id: 0,
      DeliveryType: null,
      DeliveryTypeTentative: null,
      VatRate: 0,
      VatRateId: -1,
      UserId: 0,
      UserFullName: "",
      SelectedShippingAddress: null,
      ShippingAddresses: [],
      DeliveryOptions: [],
      NewStatus: 1,
      MessageToLab: "",
      BookedBy: "",
      UserBranchId: -1,
      DeliveryBranchId: 1,
      HasVatNum: false,
      DueDate: ''
    });

    const currentSpecialOrderItem: Ref<SpecialOrderItemViewModel> = ref({
      Id: 0,
      OrderTypeId: 0,
      OrderFreeTextTypeId: null,
      OrderTypeName: "",
      PrintMedia: null,
      BorderTopMm: 0,
      BorderRightMm: 0,
      BorderBottomMm: 0,
      BorderLeftMm: 0,
      Substrate: null,
      Frame: null,
      Lamination: null,
      Colour: null,
      SubstrateColour: null,
      Backing: null,
      Size: null,
      Fixing: null,
      Moulding: null,
      Stationery: null,
      Retouching: null,
      Glass: null,
      HangingOption: null,
      WindowMount: null,
      WmBorderTopMm: 0,
      WmBorderRightMm: 0,
      WmBorderBottomMm: 0,
      WmBorderLeftMm: 0,
      OrderItemId: 0,

      Description: "",
      PriceExcludingVat: "0.00",
      DiscountPercentage: "0",
      Vat: 0,
      Quantity: 1,
    });

    const printMedias: Ref<PrintMediumViewModel[]> = ref([]);
    const printSizes: Ref<SizeViewModel[]> = ref([]);
    const printFrameTypes: Ref<FrameViewModel[]> = ref([]);
    const mouldings: Ref<MouldingViewModel[]> = ref([]);
    const glasses: Ref<GlassViewModel[]> = ref([]);
    const windowMounts: Ref<WindowMountViewModel[]> = ref([]);
    const substrates: Ref<SubstrateViewModel[]> = ref([]);
    const backings: Ref<BackingViewModel[]> = ref([]);
    const laminations: Ref<LaminationViewModel[]> = ref([]);
    const fixings: Ref<FixingViewModel[]> = ref([]);
    const substrateColours: Ref<SubstrateColourViewModel[]> = ref([]);
    const stationeries: Ref<StationeryViewModel[]> = ref([]);
    const retouchings: Ref<RetouchingViewModel[]> = ref([]);
    const deliveryOptions: Ref<DeliveryTypeViewModel[]> = ref([]);
    const deliveryZones: Ref<DeliveryZoneViewModel[]> = ref([]);
    const orderStati: Ref<{ name: string; value: number }[]> = ref([]);
    const showPreventUnpdaidStatusSelectModal: Ref<boolean> =
      ref<boolean>(false);
    const userIsForInvoicing: Ref<boolean> = ref<boolean>(false);
    const modalSelectList: Ref<UserGetQCUserListViewModel[]> = ref([]);

    const dueDate: Ref<Date | null> = ref(null);

    return {
      PopupNotification,
      discount,
      priceExVat,
      customerId,
      emailQuoteTo,
      customerReference,
      quantityArray,
      deliveryBranches,
      currency,
      vatRates,
      vatRate,
      currentSpecialOrderItem,
      specialOrder,
      orderItemTypes,
      printMedias,
      printSizes,
      printFrameTypes,
      mouldings,
      glasses,
      windowMounts,
      substrates,
      backings,
      laminations,
      fixings,
      substrateColours,
      stationeries,
      retouchings,
      deliveryOptions,
      specialOrderItems,
      deliveryZones,
      countries,
      orderStati,
      isSubmitedOrder,
      orderItemFreeTextTypes,
      showPreventUnpdaidStatusSelectModal,
      userIsForInvoicing,
      modalSelectList,
      dueDate
    };
  },
  data() {
    const wdgt: any = {};
    return {
      PopupNotificationWidget: wdgt,
    };
  },
  computed: {
    specialOrderTotalExcVat(): string {
      return this.specialOrderItems
        .reduce(
          (previous, current) =>
            current.Quantity * parseFloat(current.PriceExcludingVat) + previous,
          0,
        )
        .toFixed(2);
    },
    getTotalQuantity(): number {
      return this.specialOrderItems.reduce(
        (previous, current) => current.Quantity + previous,
        0,
      );
    },
    specialOrderTotalVatAfterDiscount(): string {
      return this.getSpecialOrderTotalVatAfterDiscount().toFixed(2);
    },
    specialOrderTotalIncVat(): string {
      const totalExVat = this.getSpecialOrderTotalVatAfterDiscount();
      const result =
        totalExVat + (totalExVat * (this.specialOrder.VatRate ?? 0)) / 100;
      return result.toFixed(2);
    },
    getSpecialOrderItemToShow(): SpecialOrderItemViewModel[] {
      return this.specialOrderItems.map((item: SpecialOrderItemViewModel) => {
        item.OrderTypeName =
          this.orderItemTypes
            .concat(this.orderItemFreeTextTypes)
            .find(
              type =>
                parseInt(type.value) ==
                (!item.OrderFreeTextTypeId
                  ? item.OrderTypeId
                  : item.OrderFreeTextTypeId),
            )?.name ?? "";
        return item;
      });
    },
    canAddSpecialOrderItem():
      | boolean
      | SizeViewModel
      | StationeryViewModel
      | DeliveryTypeViewModel {
      return this.$specialOrder.canAddSpecialOrderItem(
        this.currentSpecialOrderItem,
        this.specialOrder,
      );
    },
  },
  async mounted() {
    this.deliveryBranches = this.$specialOrder.deliveryBranches;
    this.PopupNotificationWidget = this.PopupNotification?.kendoWidget();
    this.customerId = this.$route.params.customerId as string;
    this.quantityArray = Array.from(Array(200), (x, index) => index + 1);
    $.post("/form/pms/GetCustomerNameAndShippingAddresses", {
      customerId: this.customerId,
    }).then((response: any) => {
      this.specialOrder.UserFullName = response.name;
      this.specialOrder.HasVatNum = response.hasVatNumber;
      this.specialOrder.UserBranchId = response.userBranchId;
      this.specialOrder.DeliveryBranchId = response.userBranchId;
      this.currency = getCurrencySymbol(response.userBranchId);
      this.specialOrder.ShippingAddresses = response.shippingAddresses;
      this.emailQuoteTo = response.email;
      this.specialOrder.UserId = parseInt(this.customerId ?? "0");
      this.userIsForInvoicing = !!response.isForInvoicing;
    });
    await Promise.all([
      this.getVatRates(),
      this.getSpecialOrderTypes(),
      this.getSpeciaOrderFreeTextTypes(),
      this.getPrintMedias(),
      this.getFrameTypes(),
      this.getGlasses(),
      this.getWindowMounts(),
      this.getSubstrates(),
      this.getStationeries(),
      this.getRetouchings(),
      this.getDeliveryOptions(),
      this.getDeliveryZones(),
      this.getOrderStati(),
    ]);
  },
  methods: {
    priceInVat(): string {
      return (
        this.currentSpecialOrderItem.Quantity *
        (parseFloat(this.currentSpecialOrderItem.PriceExcludingVat) +
          (parseFloat(this.currentSpecialOrderItem.PriceExcludingVat) *
            (this.specialOrder.VatRate ?? 0)) /
            100)
      ).toFixed(2);
    },
    priceAfterDiscount(): string {
      return (
        this.currentSpecialOrderItem.Quantity *
        (parseFloat(this.currentSpecialOrderItem.PriceExcludingVat) -
          (parseFloat(this.currentSpecialOrderItem.PriceExcludingVat) *
            parseFloat(this.currentSpecialOrderItem.DiscountPercentage)) /
            100)
      ).toFixed(2);
    },
    async getVatRates(): Promise<void> {
      this.vatRates = await this.$specialOrder.getVatRates();
      this.setVatRate();
    },
    async getSpecialOrderTypes(): Promise<void> {
      this.orderItemTypes = await this.$specialOrder.getSpecialOrderTypes();
    },
    async getSpeciaOrderFreeTextTypes(): Promise<void> {
      this.orderItemFreeTextTypes =
        await this.$specialOrder.getSpeciaOrderFreeTextTypes();
    },
    async getPrintMedias(): Promise<void> {
      this.printMedias = await this.$specialOrder.getPrintMedias();
    },
    async getSizes(
      entityId: number,
      applySpecialOrderMarkDown: boolean,
    ): Promise<any> {
      return await this.$specialOrder.getSizes(
        entityId,
        applySpecialOrderMarkDown,
        this.specialOrder.DeliveryBranchId,
      );
    },
    async getFrameTypes(): Promise<void> {
      this.printFrameTypes = await this.$specialOrder.getFrameTypes();
    },
    async getSubstrates(): Promise<void> {
      this.substrates = await this.$specialOrder.getSubstrates();
    },
    async getBackings(): Promise<void> {
      this.backings = await this.$specialOrder.getBackings();
    },
    async getLaminations(): Promise<void> {
      this.laminations = await this.$specialOrder.getLaminations();
    },
    async getStationeries(): Promise<void> {
      this.stationeries = await this.$specialOrder.getStationeries();
    },
    async getRetouchings(): Promise<void> {
      this.retouchings = await this.$specialOrder.getRetouchings();
    },
    async getDeliveryOptions(): Promise<void> {
      this.deliveryOptions = await this.$specialOrder.getDeliveryOptions();
    },
    async getDeliveryZones(): Promise<void> {
      this.deliveryZones = await this.$specialOrder.getDeliveryZones();
    },
    async getCountries(): Promise<void> {
      this.countries = await this.$specialOrder.getCountries();
    },
    async getOrderStati(): Promise<void> {
      this.orderStati = await this.$specialOrder.getOrderStati();
    },
    async getFixings(
      substrateId: number,
      widthInches: number,
      heightInches: number,
    ): Promise<void> {
      this.fixings = await this.$specialOrder.getFixings(
        substrateId,
        widthInches,
        heightInches,
      );
    },
    async getSubstrateColours(substrateId: number): Promise<void> {
      this.substrateColours = await this.$specialOrder.getSubstrateColours(
        substrateId,
      );
    },
    async setEmailInvoice(orderId: number, email: string): Promise<boolean> {
      return await this.$specialOrder.setEmailInvoice(orderId, email);
    },
    async addSpecialOrder(paymentData?: PaymentDataModel): Promise<number> {
      return await this.$specialOrder.addSpecialOrder(
        this.specialOrder,
        this.specialOrderItems,
        this.customerReference,
        0,
        paymentData,
      );
    },
    async getEntityPrice(
      entityId: number,
      branchId: number,
      widthInches: number,
      heightInches: number,
    ): Promise<number> {
      return await this.$specialOrder.getEntityPrice(
        entityId,
        branchId,
        widthInches,
        heightInches,
      );
    },
    async getMouldings(): Promise<void> {
      if (this.currentSpecialOrderItem.Frame) {
        this.mouldings = await this.$specialOrder.getMouldings(
          this.currentSpecialOrderItem,
        );
      }
    },
    async getGlasses(): Promise<void> {
      this.glasses = await this.$specialOrder.getGlasses();
    },
    async getWindowMounts(): Promise<void> {
      this.windowMounts = await this.$specialOrder.getWindowMounts();
    },
    changeDeliveryBranch(): void {
      this.setVatRate();
    },
    setVatRate(): void {
      const vatRate = this.vatRates.find(
        rate => rate.BranchId === this.specialOrder.DeliveryBranchId,
      );
      this.specialOrder.VatRate = vatRate?.Rate;
      this.specialOrder.VatRateId = vatRate?.Id;
    },
    calculateSpecialOrderPrice(): void {
      this.currentSpecialOrderItem.PriceExcludingVat =
        this.$specialOrder.calculateSpecialOrderPrice(
          this.currentSpecialOrderItem,
        );
    },
    async onPrintMediaChange(): Promise<void> {
      this.clearPrintFields();
      if (this.currentSpecialOrderItem.PrintMedia) {
        const data = await this.getSizes(
          this.currentSpecialOrderItem.PrintMedia.EntityId,
          false,
        );
        this.printSizes = data;
      }
    },
    specialOrderBorderTopChanged(): void {
      const top = this.currentSpecialOrderItem.BorderTopMm;
      this.currentSpecialOrderItem.BorderRightMm = top;
      this.currentSpecialOrderItem.BorderBottomMm = top;
      this.currentSpecialOrderItem.BorderLeftMm = top;
    },
    specialOrderWmBorderTopChanged(): void {
      const top = this.currentSpecialOrderItem.WmBorderTopMm;
      this.currentSpecialOrderItem.WmBorderRightMm = top;
      this.currentSpecialOrderItem.WmBorderBottomMm = top;
      this.currentSpecialOrderItem.WmBorderLeftMm = top;
    },
    async onFrameTypeChange(): Promise<void> {
      if (this.currentSpecialOrderItem.Frame === null) {
        this.clearMouldingsFields();
        return;
      }
      this.clearMouldingsFields();
      await this.getMouldings();
    },
    async onMouldingChange(): Promise<void> {
      if (this.currentSpecialOrderItem.Moulding === null) {
        return;
      }
      const data = await this.getSizes(
        this.currentSpecialOrderItem.Moulding.EntityId,
        true,
      );
      this.printSizes = data;
    },
    async onMouldingSizeChange(): Promise<void> {
      if (
        this.currentSpecialOrderItem.Size === null ||
        this.currentSpecialOrderItem.Moulding === null
      ) {
        return;
      }
      const data = await this.getSizes(
        this.currentSpecialOrderItem.Moulding.EntityId,
        true,
      );
      this.printSizes = data;
    },
    onPrintingSizeChanged(): void {
      this.calculateSpecialOrderPrice();
    },
    async onSubstrateChanged(): Promise<void> {
      if (this.currentSpecialOrderItem.Substrate === null) {
        this.currentSpecialOrderItem.Size = null;
        return;
      }
      if (
        this.currentSpecialOrderItem.Substrate &&
        this.currentSpecialOrderItem.Substrate.SupportsBacking
      ) {
        await this.getBackings();
      }
      const data = await this.getSizes(
        this.currentSpecialOrderItem.Substrate.EntityId,
        true,
      );
      this.printSizes = data;
      this.clearSubstrateFields();
    },
    async onMountChanged(): Promise<void> {
      if (
        this.currentSpecialOrderItem.WindowMount === null ||
        !this.currentSpecialOrderItem.Size
      ) {
        return;
      }
      const price = await this.getEntityPrice(
        this.currentSpecialOrderItem.WindowMount.EntityId,
        this.specialOrder.UserBranchId,
        this.currentSpecialOrderItem.Size.WidthInches,
        this.currentSpecialOrderItem.Size.HeightInches,
      );
      if (price) {
        this.currentSpecialOrderItem.WindowMount.Price = price;
      }
      this.calculateSpecialOrderPrice();
    },
    async onLaminationChanged(): Promise<void> {
      if (
        this.currentSpecialOrderItem.Lamination === null ||
        !this.currentSpecialOrderItem.Size
      ) {
        return;
      }
      const price = await this.getEntityPrice(
        this.currentSpecialOrderItem.Lamination.EntityId,
        this.specialOrder.UserBranchId,
        this.currentSpecialOrderItem.Size.WidthInches,
        this.currentSpecialOrderItem.Size.HeightInches,
      );
      if (price) {
        this.currentSpecialOrderItem.Lamination.Price = price;
      }
      this.calculateSpecialOrderPrice();
    },
    async onGlassChanged(): Promise<void> {
      if (
        this.currentSpecialOrderItem.Glass === null ||
        !this.currentSpecialOrderItem.Size
      ) {
        return;
      }
      const price = await this.getEntityPrice(
        this.currentSpecialOrderItem.Glass.EntityId,
        this.specialOrder.UserBranchId,
        this.currentSpecialOrderItem.Size.WidthInches,
        this.currentSpecialOrderItem.Size.HeightInches,
      );
      if (price) {
        this.currentSpecialOrderItem.Glass.Price = price;
      }
      this.calculateSpecialOrderPrice();
    },
    async onFixingChanged(): Promise<void> {
      if (
        this.currentSpecialOrderItem.Fixing === null ||
        !this.currentSpecialOrderItem.Size
      ) {
        return;
      }
      const price = await this.getEntityPrice(
        this.currentSpecialOrderItem.Fixing.EntityId,
        this.specialOrder.UserBranchId,
        this.currentSpecialOrderItem.Size.WidthInches,
        this.currentSpecialOrderItem.Size.HeightInches,
      );
      if (price) {
        this.currentSpecialOrderItem.Fixing.Price = price;
      }
      this.calculateSpecialOrderPrice();
    },
    async onSubstrateSizeChanged(): Promise<void> {
      if (
        this.currentSpecialOrderItem.Size &&
        this.currentSpecialOrderItem.Substrate != null
      ) {
        const price = await this.getEntityPrice(
          this.currentSpecialOrderItem.Substrate.EntityId,
          this.specialOrder.UserBranchId,
          this.currentSpecialOrderItem.Size.WidthInches,
          this.currentSpecialOrderItem.Size.HeightInches,
        );
        if (price) {
          this.currentSpecialOrderItem.Substrate.Price = price;
        }
        if (this.currentSpecialOrderItem.Substrate.SupportsLamination) {
          await this.getLaminations();
        }
        await this.getFixings(
          this.currentSpecialOrderItem.Substrate.Id,
          this.currentSpecialOrderItem.Size.WidthInches,
          this.currentSpecialOrderItem.Size.HeightInches,
        );
        await this.getSubstrateColours(
          this.currentSpecialOrderItem.Substrate.Id,
        );
      }
      this.calculateSpecialOrderPrice();
    },
    clearSubstrateFields(): void {
      this.fixings = [];
      this.substrateColours = [];
      this.laminations = [];
      this.currentSpecialOrderItem.SubstrateColour = null;
      this.currentSpecialOrderItem.Fixing = null;
      this.currentSpecialOrderItem.Lamination = null;
      this.currentSpecialOrderItem.Backing = null;
    },
    clearPrintFields(): void {
      this.printSizes = [];
      this.currentSpecialOrderItem.Size = null;
      this.currentSpecialOrderItem.BorderTopMm = 0;
      this.currentSpecialOrderItem.BorderRightMm = 0;
      this.currentSpecialOrderItem.BorderBottomMm = 0;
      this.currentSpecialOrderItem.BorderLeftMm = 0;
    },
    clearMouldingsFields(): void {
      this.printSizes = [];
      this.currentSpecialOrderItem.Moulding = null;
      this.currentSpecialOrderItem.Size = null;
      this.currentSpecialOrderItem.Glass = null;
      this.currentSpecialOrderItem.WindowMount = null;
      this.currentSpecialOrderItem.WmBorderTopMm = 0;
      this.currentSpecialOrderItem.WmBorderRightMm = 0;
      this.currentSpecialOrderItem.WmBorderBottomMm = 0;
      this.currentSpecialOrderItem.WmBorderLeftMm = 0;
    },
    onOrderItemTypeChanged(): void {
      this.currentSpecialOrderItem.PrintMedia = null;
      this.currentSpecialOrderItem.Frame = null;
      this.currentSpecialOrderItem.Substrate = null;
      this.currentSpecialOrderItem.Stationery = null;
      this.currentSpecialOrderItem.Retouching = null;
      this.currentSpecialOrderItem.OrderFreeTextTypeId = null;
      this.specialOrder.DeliveryTypeTentative = null;
      this.clearPrintFields();
      this.clearSubstrateFields();
      this.clearMouldingsFields();
      this.calculateSpecialOrderPrice();

      this.currentSpecialOrderItem.Quantity = 1;
      this.currentSpecialOrderItem.PriceExcludingVat = "0.00";
      if (
        this.currentSpecialOrderItem.OrderTypeId === 6 &&
        this.specialOrder.SelectedShippingAddress
      ) {
        this.populateDeliveryOptions();
      }
      if (this.isUkOnlyOrder()) {
        this.specialOrder.DeliveryBranchId = 1; /*UK Branch Id*/
      } else {
        if (this.specialOrder.SelectedShippingAddress) {
          const country = this.countries.find(
            country =>
              country.value ===
                this.specialOrder.SelectedShippingAddress?.CountryId ?? 1,
          );
          if (country) {
            this.specialOrder.DeliveryBranchId =
              country.CourierDeliveryBranchId ??
              country.PostageDeliveryBranchId;
          }
        }
      }
      this.currentSpecialOrderItem.OrderTypeName =
        this.orderItemTypes
          .concat(this.orderItemFreeTextTypes)
          .find(
            type =>
              parseInt(type.value) ==
              (!this.currentSpecialOrderItem.OrderFreeTextTypeId
                ? this.currentSpecialOrderItem.OrderTypeId
                : this.currentSpecialOrderItem.OrderFreeTextTypeId),
          )?.name ?? "";
    },
    onOrderItemFreeTextTypeChanged(): void {
      this.currentSpecialOrderItem.OrderTypeName =
        this.orderItemTypes
          .concat(this.orderItemFreeTextTypes)
          .find(
            type =>
              parseInt(type.value) ==
              (!this.currentSpecialOrderItem.OrderFreeTextTypeId
                ? this.currentSpecialOrderItem.OrderTypeId
                : this.currentSpecialOrderItem.OrderFreeTextTypeId),
          )?.name ?? "";
    },
    onStationeryChanged(): void {
      this.calculateSpecialOrderPrice();
    },
    populateDeliveryOptions(): void {
      this.specialOrder.DeliveryTypeTentative = null;
      this.specialOrder.DeliveryOptions = [];
      const address = this.specialOrder.SelectedShippingAddress;
      if (!address) {
        const cis = this.deliveryOptions.find(
          item => item.Name === "Collect In Studio",
        );
        if (cis) {
          this.specialOrder.DeliveryOptions = [cis];
          this.specialOrder.DeliveryTypeTentative = cis;
          return;
        }
      }
      const deliveryOptions = this.availableDeliveryMethodsForAddress(address);
      for (let i = 0; i < deliveryOptions.length; i++) {
        this.specialOrder.DeliveryOptions.push(deliveryOptions[i]);
      }
    },

    isUkOnlyOrder(): boolean {
      let result = false;

      // Check existing special order items
      if (this.specialOrderItems.length != 0) {
        for (let i = 0; i < this.specialOrderItems.length; i++) {
          const item = this.specialOrderItems[i];
          if (
            item.OrderTypeId == 2 /*Framing */ ||
            item.OrderTypeId == 3 /*mounting*/
          ) {
            result = true;
            break;
          }
        }
      }

      // Check current special order
      result =
        result ||
        this.currentSpecialOrderItem.OrderTypeId == 2 /*Framing */ ||
        this.currentSpecialOrderItem.OrderTypeId == 3; /*mounting*/

      return result;
    },
    availableDeliveryMethodsForAddress(deliveryAddress: any): any {
      return this.$specialOrder.availableDeliveryMethodsForAddress(
        deliveryAddress,
        this.specialOrder.DeliveryBranchId,
        this.deliveryZones,
        this.deliveryOptions,
      );
    },
    onDeliveryAddressChanged(): void {
      this.populateDeliveryOptions();
    },
    getSpecialOrderTotalVatAfterDiscount(): number {
      return this.specialOrderItems.reduce(
        (previous, current) =>
          current.Quantity *
            (parseFloat(current.PriceExcludingVat) -
              (parseFloat(current.PriceExcludingVat) *
                parseFloat(current.DiscountPercentage)) /
                100) +
          previous,
        0,
      );
    },
    addSpecialOrderItem(): void {
      if (this.currentSpecialOrderItem.OrderTypeId === 6) {
        if (this.specialOrderItems.some(item => item.OrderTypeId === 6)) {
          this.PopupNotificationWidget?.show(
            "Delivery details have already been added",
            "error",
          );
          return;
        }
        this.specialOrder.DeliveryType =
          this.specialOrder.DeliveryTypeTentative;
      }
      this.specialOrderItems.push(this.currentSpecialOrderItem);
      this.currentSpecialOrderItem = this.$specialOrder.initSpecialOrderItem();
    },
    specialOrderItemExcVat(item: SpecialOrderItemViewModel): string {
      return (item.Quantity * parseFloat(item.PriceExcludingVat)).toFixed(2);
    },
    specialOrderItemDiscount(item: SpecialOrderItemViewModel): string {
      return `${parseFloat(item.DiscountPercentage).toFixed(2)} %`;
    },
    specialOrderItemPriceAfterDiscount(
      item: SpecialOrderItemViewModel,
    ): string {
      const price: number = parseFloat(item.PriceExcludingVat);
      return (
        item.Quantity *
        (price - (price * parseFloat(item.DiscountPercentage)) / 100)
      ).toFixed(2);
    },
    specialOrderItemPriceIncVat(item: SpecialOrderItemViewModel): string {
      const price: number = parseFloat(item.PriceExcludingVat);
      return (
        item.Quantity *
          (price - (price * parseFloat(item.DiscountPercentage)) / 100) +
        ((price - (price * parseFloat(item.DiscountPercentage)) / 100) *
          (this.specialOrder.VatRate ?? 0)) /
          100
      ).toFixed(2);
    },
    onRemoveSpecialItemClick(index: number): void {
      this.specialOrderItems.splice(index, 1);
      if (!this.specialOrderItems.some(item => item.OrderTypeId === 6)) {
        this.specialOrder.DeliveryType = null;
      }
    },
    onRemoveAllClick(): void {
      this.specialOrderItems = [];
    },
    onViewQuoteClick(): void {
      window.open(
        "/form/pms/CreateOrderInvoiceFromOrderId" +
          "?orderId=" +
          this.specialOrder.Id +
          "&email=null&emailUser=false",
        "_blank",
        "fullscreen=yes",
      );
    },
    async openPreventUnpaidModal(): Promise<void> {
      const response: GetQCUserListViewModel =
        await this.$account.getAdminUsers();
      if (!response || !response.Users.length) {
        this.PopupNotificationWidget?.show(
          `Sorry you don't have permission`,
          "error",
        );
        return;
      }

      this.modalSelectList = response.Users;
      this.showPreventUnpdaidStatusSelectModal = true;
    },
    async onClosePreventUnpaidModal(
      r: PreventUnpaidStatusSelectModalResponse,
    ): Promise<void> {
      // cancel
      if (!r.save) {
        this.showPreventUnpdaidStatusSelectModal = false;
        return;
      }

      // manually paid validation
      if (r.paidType === 1 && (!r.manualPaymentMethod || !r.user)) {
        this.PopupNotificationWidget?.show(
          `Sorry you can't change status without selected manual payment method or user`,
          "error",
        );
        this.showPreventUnpdaidStatusSelectModal = false;
        return;
      }

      const paymentData: PaymentDataModel = {
        PaidType: r.paidType,
        PaidDate: r.paidDate,
        ManualPaymentMethod: r.manualPaymentMethod?.Id ?? 0,
        ManualPaymentUserId: r.user?.Id,
        ManualPaymentNote: r.note,
      };

      await this.submitSpecialOrder(paymentData);
      this.showPreventUnpdaidStatusSelectModal = false;
    },
    async submitSpecialOrder(paymentData?: PaymentDataModel): Promise<void> {
      const isQuote = !paymentData;
      if (
        !isQuote &&
        !this.specialOrder.DeliveryType &&
        this.specialOrder.SelectedShippingAddress
      ) {
        this.PopupNotificationWidget?.show(
          "You must add a delivery row before finalising, or change Delivery Address to Collect In Studio",
          "error",
        );
        return;
      }

      this.specialOrder.DueDate = this.dueDate?.toISOString() ?? '';

      const orderId = await this.addSpecialOrder(paymentData);
      this.specialOrderItems = [];
      this.currentSpecialOrderItem = this.$specialOrder.initSpecialOrderItem();
      const orderAction = "Added";
      if (isQuote) {
        this.PopupNotificationWidget?.show(
          `Order ${orderAction} & Awaiting Finalisation. Order No is: ${orderId}`,
          "success",
        );
      } else {
        this.PopupNotificationWidget?.show(
          `Order ${orderAction} & Finalised. Order No is: ${orderId}`,
          "success",
        );
      }

      this.isSubmitedOrder = !!orderId;
    },
    onBackToCustomerOrders(): void {
      const _window: any = window;
      _window.router = this.$router;
      _window.router.push(`/customers/${this.specialOrder.UserId}`);
    },
    async onEmailQuoteClick(): Promise<void> {
      const orderId = await this.addSpecialOrder();
      const response = await this.setEmailInvoice(orderId, this.emailQuoteTo);
      if (response) {
        this.PopupNotificationWidget?.show(`Quote Emailed`, "success");
      }
    },
  },
});
