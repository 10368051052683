
import { defineComponent, PropType, Ref, ref } from "vue";
import GenericModalVue from "@/components/Modal/GenericModal.vue";
import { Notification as KNotification } from "@progress/kendo-popups-vue-wrapper";
import PreventUnpaidStatusSelectModalVue from "@/components/Modal/PreventUnpaidStatusSelectModal.vue";
import axios from "axios";

export default defineComponent({
  name: "OrdersBulkEditModal",
  components: {
    PreventUnpaidStatusSelectModalVue,
    "k-notification": KNotification,
    GenericModalVue,
  },
  props: {
    show: Object as PropType<boolean>,
    orderIds: Object as PropType<number[]>,
  },
  data() {
    return {
      popupNotificationWidget: {} as any,
    };
  },
  setup() {
    const deliveryBranches: Ref<{ Id: number; Name: string }[]> = ref([]);
    const popupNotification: Ref = ref(null);
    const saving: Ref<boolean> = ref(false);
    const selectedBranchId: Ref<number | null> = ref(null);

    return {
      deliveryBranches,
      popupNotification,
      selectedBranchId,
      saving,
    };
  },
  mounted() {
    this.deliveryBranches = this.$specialOrder.deliveryBranches;
  },
  watch: {
    async show(val) {
      if (val === false || !this.orderIds) {
        return;
      }
      this.popupNotificationWidget = this.popupNotification?.kendoWidget();
    },
  },
  methods: {
    close(): void {
      this.$emit("close");
    },
    oBackDropClick(event: any): void {
      if (event.target.className === "modal-wrapper") {
        this.close();
      }
    },
    async saveChanges(): Promise<void> {
      try {
        this.saving = true;
        const orderIds = this.orderIds ?? [];
        const batchSize = 10;
        for (let i = 0; i < orderIds.length; i += batchSize) {
          const batch = orderIds.slice(i, i + batchSize);
          await axios.post("/form/pms/SetOrderProductionBranchId", {
            branchId: this.selectedBranchId,
            orderIds: batch,
          });
        }

        this.popupNotificationWidget?.show("Orders were updated", "success");
        this.saving = false;
        this.$emit("close");
      } catch {
        this.popupNotificationWidget?.show(
          "Something went wrong saving the production branch",
          "error",
        );
      }
    },
    onStatusChange(e: any): void {
      this.selectedBranchId = Number(e.target.value);
    },
  },
});
