
import { defineAsyncComponent, defineComponent, ref, markRaw } from "vue";
import { TabStrip as KTabStrip, TabStripTab as KTabStripTab } from "@progress/kendo-vue-layout";
import DashboardGrid from "@/components/DashboardGrid.vue";

declare var $: any;

export default defineComponent({
  name: "OrderDashboard",
  components: {
    KTabStrip,
    KTabStripTab,
    DashboardGrid,
  },
  setup() {
    const toolbar = ref(null);
    const tabComponent = ref(null);

    const selectedTab = ref(0);

    return {
      toolbar,
      tabComponent,
      selectedTab
    };
  },
  data() {
    return {
      tabs: [
        {
          id: 0,
          title: "Summary",
          contentString: "./Tabs/SummaryTab.vue",
          component: null,
          componentName: "SummaryTab",
          gridIds: [
            "#dashboardOrdersCountSummaryGrid",
            "#dashboardOrdersValueGrid",
            "#dashboardRedosPercentageGrid",
            "#dashboardRefundPercentageGrid",
          ],
        },
        {
          id: 1,
          title: "Orders",
          contentString: "./Tabs/OrdersTab.vue",
          component: null,
          componentName: "OrdersTab",
          gridIds: [
            "#dashboardOrdersCountGrid",
            "#dashboardFramedASFOrdersCountGrid",
            "#dashboardUnframedASFOrdersCountGrid",
            "#dashboardPrintOnlyOrdersCountGrid",
            "#dashboardPrintOrdersBespokeMountingCountGrid",
            "#dashboardPrintOrdersBespokeFramingCountGrid",
            "#dashboardPrintOrdersBespokeMixedCountGrid",
          ],
        },
        {
          id: 2,
          title: "Redo",
          contentString: "./Tabs/RedoOrdersTab.vue",
          component: null,
          componentName: "RedoOrdersTab",
          gridIds: [
            "#dashboardRedosCountGrid",
            "#dashboardRedosPercentageOrdersTabGrid",
            "#dashboardProductionIssueRedosCountGrid",
            "#dashboardProductionIssueRedosPercentageGrid",
            "#dashboardDeliveryIssueRedosCountGrid",
            "#dashboardDeliveryIssueRedosPercentageGrid",
            "#dashboardRedosValueGrid",
            "#dashboardDeliveryIssueRedosValueGrid",
            "#dashboardProductionIssueRedosValueGrid",
          ],
        },
        {
          id: 3,
          title: "Refund",
          contentString: "./Tabs/RefundOrdersTab.vue",
          component: null,
          componentName: "RefundOrdersTab",
          gridIds: [
            "#dashboardRefundsCountGrid",
            "#dashboardRefundPercentageRefundTabGrid",
            "#dashboardRefundValueGrid",
            "#dashboardRefundValuePercentageGrid"
          ],
        },
        {
          id: 4,
          title: "Orders on hold",
          contentString: "./Tabs/OrdersOnHoldTab.vue",
          component: null,
          componentName: "OrdersOnHoldTab",
          gridIds: [
            "#dashboardOrdersOnHoldGrid",
          ],
        },
        {
          id: 5,
          title: "Over SLA",
          contentString: "./Tabs/OrdersOverSlaTab.vue",
          component: null,
          componentName: "OrdersOverSlaTab",
          gridIds: [
            "#dashboardSLAOrdersCountGrid",
            "#dashboardSLAOrdersPercentageGrid",
            "#dashboardSLADaysCountGrid"
          ],
        },
      ],
    }
    
  },
  mounted() {
    const _window: any = window;

    const endDate = new Date();
    endDate.setHours(0, 0, 0, 0);
    const startDate = new Date(endDate.getFullYear(), endDate.getMonth() - 2, 1);

    endDate.setDate(1);
    endDate.setMonth(endDate.getMonth() + 1);

    const utcStartDate = new Date(Date.UTC(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), 0, 0, 0, 0));

    const utcEndDate = new Date(Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate(), 0, 0, 0, 0));

    $.post("/form//Grid/DashboardDateRangeFilters", {
          StartDate: utcStartDate.toISOString(),
          EndDate: utcEndDate.toISOString(),
        }, (date: any) => {});

    _window.addPrefix = (value: any) => {
      const formatter = new Intl.NumberFormat("en-GB", {
        style: "decimal",
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      });
      return `£\u00A0${formatter.format(value)}`;
    }

    _window.addPercentPostfix = (value: any) => {
      return `${value}%`;
    }

    this.initializeToolbar();
    this.onSelectedTab({ selected: 0 });
  },
  methods: {
    initializeToolbar() {
      const toolbar = this.toolbar;
      const _window: any = window;
      const _this = this;

      $.get("/form/grid/DashboardToolbarPartial", {}, (html: string) => {
        html = html.replace(/^[^]*?<div/i, "<div");
        html = html.replace(/\/grid\//gi, "/form/$&");
        $(toolbar).html(html);
      });
      _window.onApplyClick = this.onApplyClick;
    },
    async onSelectedTab(e: any) {
      this.selectedTab = e.selected;
      const tab = this.tabs[this.selectedTab];
      
      if (!tab.component) {
        try {
          const module = await import(`./Tabs/${tab.componentName}.vue`);
          tab.component = markRaw(module.default);
        } catch (error) {
          console.error(`Failed to load component: ${tab.componentName}`, error);
        }
      }
    },
    onApplyClick(e: any) {
      const startDate = new Date($("#startDatePicker").data("kendoDatePicker").value());
      const endDate = new Date($("#endDatePicker").data("kendoDatePicker").value());

      endDate.setDate(1);
      endDate.setMonth(endDate.getMonth() + 1);

      const utcStartDate = new Date(Date.UTC(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), 0, 0, 0, 0));

      const utcEndDate = new Date(Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate(), 0, 0, 0, 0));
      
      if (endDate < startDate) {
        alert("End date should be greater than start date");
        return;
      } else {

        $.post("/form//Grid/DashboardDateRangeFilters", {
          StartDate: utcStartDate.toISOString(),
          EndDate: utcEndDate.toISOString(),
        }, (data: any) => {
          if (data.success) {

            this.tabs.find(tab => tab.id === this.selectedTab)?.gridIds.forEach(gridSelector => {
              const grid = $(gridSelector).data("kendoGrid");
              grid.dataSource.read();
            });
          }
        });

      }
    }
  },
});
