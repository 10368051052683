
import {defineComponent, PropType, Ref, ref} from "vue";
import {OrderStatusModel} from "@/core/types/orderStatusModel";
import {OrderStatus, OrderStatuses} from "@/core/static-data/orderStatuses";
import GenericModalVue from "@/components/Modal/GenericModal.vue";
import {GetQCUserListViewModel, UserGetQCUserListViewModel} from "@/core/types/user/getQCUserListViewModel";
import PreventUnpaidStatusSelectModalResponse from "@/core/types/modal/preventUnpaidStatusSelectModalResponse";
import {formatDate} from "@telerik/kendo-intl";
import {getUtcDate} from "@/core/utils";
import {Notification as KNotification} from "@progress/kendo-popups-vue-wrapper";
import PreventUnpaidStatusSelectModalVue from "@/components/Modal/PreventUnpaidStatusSelectModal.vue";
import axios from "axios";

export default defineComponent({
  name: 'OrdersBulkEditModal',
  components: {
    PreventUnpaidStatusSelectModalVue,
    "k-notification": KNotification,
    GenericModalVue
  },
  props: {
    show: Object as PropType<boolean>,
    orderIds: Object as PropType<number[]>
  },
  data() {
    return {
      OrderStatuses,
      popupNotificationWidget: {} as any
    }
  },
  setup() {
    const selectedOrderStatusId: Ref<number | null> = ref(null);
    const popupNotification: Ref = ref(null);
    const qcUsers: Ref<UserGetQCUserListViewModel[]> = ref([]);
    const orders: Ref<any[]> = ref([]);
    const isLoading: Ref<boolean> = ref(false);

    // QC modal.
    const showQcWarningModal: Ref<boolean> = ref(false);
    const qcWarningModalText: Ref<string | null> = ref(null);

    // Unpaid warning modal.
    const showUnpaidWarningModal: Ref<boolean> = ref(false);

    return {
      selectedOrderStatusId,
      popupNotification,
      qcUsers,
      orders,
      isLoading,

      // QC modal.
      showQcWarningModal,
      qcWarningModalText,

      // Unpaid warning modal.
      showUnpaidWarningModal
    }
  },
  watch: {
    async show(val) {
      if (val === false || !this.orderIds) {
        return;
      }

      this.popupNotificationWidget = this.popupNotification?.kendoWidget();

      this.isLoading = true;

      await this.loadQcUsers();

      this.orders = [];

      const query = this.orderIds.map(orderId => `orderIds=${orderId}`).join('&');

      const response = await axios.get(`/form/pms/GetOrders?${query}`);

      if (response?.data) {
        this.orders = response.data;
      }

      this.isLoading = false;
    }
  },
  computed: {
    userIsForInvoice(): boolean {
      return !!this.orders?.every(o => o.UserIsForInvoice);
    }
  },
  methods: {
    close() {
      this.$emit("close");
    },
    oBackDropClick(event: any): void {
      if (event.target.className === "modal-wrapper") {
        this.close();
      }
    },
    setDisableToOrderStatuses(item: OrderStatusModel): boolean {
      const disabledOrderStatusIds: OrderStatus[] = [
        OrderStatus.ToBeRedone,
        OrderStatus.RefundInitiated,
        OrderStatus.Packed,
        OrderStatus.OrderFailed,
        OrderStatus.PaymentFailed,
        OrderStatus.ProcessingPayment,
        OrderStatus.SuspendedOrder,
        OrderStatus.EmbryonicOrder,
        OrderStatus.CardRequired,
        OrderStatus.ShippingFailed,
      ];

      return disabledOrderStatusIds.includes(item.Id);
    },
    onStatusChange(e: any): void {
      const qcStatusIds: OrderStatus[] = [
        OrderStatus.FramingComplete,
        OrderStatus.MountingComplete,
        OrderStatus.InDispatch,
        OrderStatus.DispatchQC,
      ];

      const orderStatusId = Number(e.target.value);

      this.selectedOrderStatusId = orderStatusId;

      if (this.isSelectedUnpaidStatus()) {
        if (this.qcUsers.length > 0) {
          this.showUnpaidWarningModal = true;
        }

        return;
      }

      if (qcStatusIds.includes(orderStatusId)) {
        this.setQcModalMessage(orderStatusId);
        if (this.qcUsers.length > 0) {
          this.showQcWarningModal = true;
        }

        return;
      }
    },
    onUnpaidWarningModalClose(
      response: PreventUnpaidStatusSelectModalResponse,
    ): void {
      if (response.save && this.orders) {
        if (
          (response.paidType === 1 && response.manualPaymentMethod == null) ||
          (response.paidType === 1 && response.user == null)
        ) {
          this.popupNotificationWidget?.show(
            `Sorry you can't change status without selected manual payment method or user`,
            "error",
          );
          this.showUnpaidWarningModal = false;
          this.selectedOrderStatusId = null;
          return;
        }
        if (response.paidType === 1) {
          this.orders.forEach(order => order.IsManualPaid = true);
        }
        if (response.paidType === 2) {
          this.orders.filter(order => order.OrderTypeId === 11).forEach(order => order.IsASFForInvoice = true);
        }
        if (response.paidType === 2) {
          this.orders.filter(order => !order.IsFinalised).forEach(order => order.IsInHouseForInvoice = true);
        }
        if (response.paidType === 2) {
          this.orders.filter(order => order.OrderTypeId !== 11).forEach(order => order.IsPrintForInvoice = true);
        }
        this.orders.forEach(order => order.PaidDate =
          response.paidType === 1
            ? formatDate(response.paidDate, "dd/MM/yyyy")
            : formatDate(getUtcDate(), "dd/MM/yyyy"));
        this.orders.forEach(order => order.IsAddedManualPayment = true);
        this.orders.forEach(order => order.ManualPaymentMethod = response.manualPaymentMethod?.Id ?? 0);
        this.orders.forEach(order => order.ManualPaymentUserId = response.user?.Id);
        this.orders.forEach(order => order.ManualPaymentNote = response.note);
      }
      this.showUnpaidWarningModal = false;
    },
    onQcWarningModalClose(response: any): void {
      this.showQcWarningModal = false;

      if (!this.selectedOrderStatusId) {
        return;
      }

      if (!response || !response.model) {
        this.popupNotificationWidget?.show(
          "Sorry you can't change status to Printed or Packed without selected quality control user",
          "error",
        );

        this.$emit("close");

        return;
      }

      if (this.selectedOrderStatusId === OrderStatus.InDispatch) {
        this.orders.forEach(order => order.PrintQCUserId = response.model);
        this.orders.forEach(order => order.CheckedPrintQCUserId = response.model);
      }
      if (this.selectedOrderStatusId === OrderStatus.DispatchQC) {
        this.orders.forEach(order => order.DispatchQCUserId = response.model);
        this.orders.forEach(order => order.CheckedDispatchQCUserId = response.model);
      }
      if ([OrderStatus.FramingComplete, OrderStatus.MountingComplete].includes(this.selectedOrderStatusId)) {
        this.orders.forEach(order => order.FramingMountingQCUserId = response.model);
        this.orders.forEach(order => order.CheckedFramingMountingQCUserId = response.model);
      }
    },
    isSelectedUnpaidStatus(): boolean {
      const unpaidStatuses = OrderStatuses.filter(
        status =>
          status.Id === OrderStatus.ProcessingPayment ||
          status.Id === OrderStatus.CardRequired ||
          status.Id === OrderStatus.PaymentFailed ||
          status.Id === OrderStatus.SuspendedOrder ||
          status.Id === OrderStatus.EmbryonicOrder
      );
      const cancelledOrderStatus = OrderStatuses.find(status => status.Id === OrderStatus.Cancelled);

      const wasUnpaid: boolean = unpaidStatuses.some(
        (status: OrderStatusModel) => this.orders?.some(o => o.Status === status.Id),
      );

      const wasUnpaidBeforeCancel = !!this.orders?.every(o =>
        unpaidStatuses
          .map(status => status.Name)
          .some(name => o.OrderStatusHistory?.length === 0
            || o.OrderStatusHistory
                .findLast((history: any) => history.NewStatus === cancelledOrderStatus?.Name)?.OldStatus === name),
      );
      const isCancelledOrOnHold = this.selectedOrderStatusId === OrderStatus.Cancelled
        || this.selectedOrderStatusId === OrderStatus.OnHold
      if (wasUnpaid && isCancelledOrOnHold) {
        return false;
      }

      const wasCancelled = !!this.orders?.every(o => o.Status == OrderStatus.Cancelled);

      return (
        (wasUnpaid && !isCancelledOrOnHold) ||
        (wasUnpaidBeforeCancel && wasCancelled)
      );
    },
    async loadQcUsers(): Promise<void> {
      const response: GetQCUserListViewModel = await this.$account.getAdminUsers();

      if (!response) {
        this.popupNotificationWidget?.show(
          `Sorry you don't have permission to change status to Printed or Packed it can make only super admin`,
          "error",
        );
      }

      if (response.Users.length > 0) {
        this.qcUsers = response.Users;
      }
    },
    async saveChanges(): Promise<void> {
      if (!this.orders) {
        return;
      }

      this.isLoading = true;

      this.orders.forEach(order => order.Status = this.selectedOrderStatusId);

      try {
        const batchSize = 5;

        for (let i = 0; i < this.orders.length; i+=batchSize) {
          const batch = this.orders.slice(i, i + batchSize);

          await axios.post("/form/pms/SetOrders", batch);
        }

        this.popupNotificationWidget?.show(
          "Orders were updated",
          "success",
        );

        this.isLoading = false;

        this.$emit("close");
      }
      catch {
        this.popupNotificationWidget?.show(
          "Something went wrong",
          "error",
        );

        this.isLoading = false;
      }
    },
    setQcModalMessage(orderStatusId: number): void {
      const texts: {[key: number]: string} = {
        [OrderStatus.FramingComplete]:
          "Has the framing passed the QC checks? Your name will be recorded as the quality checker",
        [OrderStatus.MountingComplete]:
          "Has the mounting passed the QC checks? Your name will be recorded as the quality checker",
        [OrderStatus.InDispatch]:
          "Has the print passed the QC checks? Your name will be recorded as the quality checker",
        [OrderStatus.DispatchQC]:
          "Have you checked the order to ensure: address, number of prints, COAS, Stickers, Inserts, size of prints, framed or unframed parameters are correct?",
        [OrderStatus.MountingFramingQC]:
          "Have you checked the order to ensure: mounting and/or framing parameters are correct?"
      };

      this.qcWarningModalText = texts[orderStatusId] || null;
    },
  },
});
