import axios from "axios";
import {
  ProductivityActivity,
  ProductivityDataAggregate,
  ProductivityLevel,
  ProductivityResponse,
} from "../types/dashboard/ProductivityData";
import {
  Branch,
  branches,
  noneBranch,
  PostCalculationAdjustment,
} from "../types/post-calculation-adjustments/postCalculationAdjustment";

const baseUrl = () => "/form/grid";

// ----------------------------------------------------------------------------

const urlCombine = (...args: string[]) => args.join("/");

// ----------------------------------------------------------------------------

const toIsoStr = (dt: Date): string => {
  const day = `${dt.getDate()}`.padStart(2, "0");
  const month = `${dt.getMonth() + 1}`.padStart(2, "0");
  const year = `${dt.getFullYear()}`;

  return `${year}-${month}-${day}`;
};

// ----------------------------------------------------------------------------

const queryStringOf = (parms: Record<any, any>) => {
  return Object.entries(parms)
    .map(([k, v]) => `${k}=${v}`)
    .join("&");
};

// ----------------------------------------------------------------------------

const query = (filters: ProductivityFilters): string => {
  return queryStringOf({
    level: filters.level,
    activity: filters.activity,
    startDate: toIsoStr(filters.startDate),
    endDate: toIsoStr(filters.endDate),
    aggregate: filters.aggregate as number,
  });
};

// ----------------------------------------------------------------------------

const url = (actionName: string, filters?: ProductivityFilters): string => {
  const path = urlCombine(baseUrl(), actionName);

  return filters ? `${path}?${query(filters)}` : path;
};

// ----------------------------------------------------------------------------

export type ProductivityFilters = {
  level: ProductivityLevel;
  activity: ProductivityActivity;
  startDate: Date;
  endDate: Date;
  aggregate: ProductivityDataAggregate;
};

// ----------------------------------------------------------------------------
let etag = "";
async function get(
  actionName: string,
  filters: ProductivityFilters,
): Promise<ProductivityResponse> {
  const endpoint = url(actionName, filters);
  const headers: any = {};
  if (etag) {
    headers["If-None-Match"] = etag;
  }
  const response = await axios.get<ProductivityResponse>(endpoint, headers);
  if (response.status == 200) {
    etag = response.headers["etag"];
  }

  return response.data;
}

// ----------------------------------------------------------------------------

export async function productivity(
  filters: ProductivityFilters,
): Promise<ProductivityResponse> {
  return await get("productivity", filters);
}

// ----------------------------------------------------------------------------

export async function loadAdjustments(): Promise<PostCalculationAdjustment[]> {
  const response = await axios.get<any>(url("LoadAdjustments"));
  const branch = (id: number): Branch => {
    return branches.find(b => b.Id == id) ?? noneBranch();
  };

  return response.data.map((o: any) => {
    return {
      Id: o.Id,
      DeductBranch: branch(o.DeductBranchId),
      AddBranch: branch(o.AddBranchId),
      Date: new Date(Date.parse(o.Date)),
      Note: o.Note,
      SummaryOrders: o.SummaryOrders,
      GeneralPrintingPrints: o.GeneralPrintingPrints,
      GeneralDispatchAsfPrintOnlyOrders: o.GeneralDispatchAsfPrintOnlyOrders,
      GeneralDispatchAsfFramedOrders: o.GeneralDispatchAsfFramedOrders,
      MagnumPrintingPrints: o.MagnumPrintingPrints,
      MagnumDispatchAsfPrintOnlyOrders: o.MagnumDispatchAsfPrintOnlyOrders,
      MagnumDispatchAsfFramedOrders: o.MagnumDispatchAsfFramedOrders,
      IsLoading: false,
    };
  });
}

// ----------------------------------------------------------------------------

export async function saveAdjustment(
  adjustment: PostCalculationAdjustment,
): Promise<void> {
  const response = await axios.post(url("SaveAdjustment"), {
    Id: adjustment.Id,
    DeductBranchId: adjustment.DeductBranch.Id,
    AddBranchId: adjustment.AddBranch.Id,
    Date: adjustment.Date,
    Note: adjustment.Note,
    SummaryOrders: adjustment.SummaryOrders,
    GeneralPrintingPrints: adjustment.GeneralPrintingPrints,
    GeneralDispatchAsfPrintOnlyOrders: adjustment.GeneralDispatchAsfPrintOnlyOrders,
    GeneralDispatchAsfFramedOrders: adjustment.GeneralDispatchAsfFramedOrders,
    MagnumPrintingPrints: adjustment.MagnumPrintingPrints,
    MagnumDispatchAsfPrintOnlyOrders: adjustment.MagnumDispatchAsfPrintOnlyOrders,
    MagnumDispatchAsfFramedOrders: adjustment.MagnumDispatchAsfFramedOrders,
  });

  if (response.status != 200) {
    console.error(response);
    throw new Error("Save failed");
  }
}

// ----------------------------------------------------------------------------

export async function deleteAdjustment(
  adjustment: PostCalculationAdjustment,
): Promise<void> {
  const response = await axios.post(url("DeleteAdjustment"), {
    id: adjustment.Id,
  });

  if (response.status != 200) {
    console.error(response);
    throw new Error("Delete failed");
  }
}

// ----------------------------------------------------------------------------
