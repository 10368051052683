
import { defineComponent, ref, Ref } from "vue";
import * as ku from "@/core/kendoUtils";
import { Note, OrderNoteType } from "@/core/types/note";
import NotesList from "@/components/NotesList.vue";
import OrdersBulkEditModal from "@/components/Modal/OrdersBulkEditModal.vue";
import BulkEditBranchModal from "@/components/Modal/BulkEditBranchModal.vue";
import { OrderStatus, OrderStatuses } from "@/core/static-data/orderStatuses";
import GenericModalVue from "@/components/Modal/GenericModal.vue";
import { TabStrip as KTabStrip } from "@progress/kendo-vue-layout";
import {
  Button as KButton,
  ButtonGroup as KButtonGroup,
} from "@progress/kendo-vue-buttons";
import axios from "axios";
import CustomerProducts from "@/views/CustomerProducts.vue";
import CustomerInvoices from "@/views/CustomerInvoices.vue";
import CustomerSales from "@/views/CustomerSales.vue";

declare var $: any;

//#region Country

type Country = {
  Id: number;
  Name: string;
  Code: string;
};
//#endregion

//#region Branch
enum BranchCodes {
  GB = 1,
  DE = 2,
  US = 3,
}

type Branch = {
  Id: BranchCodes;
  Name: string;
};
function makeBranch(id: BranchCodes, name: string) {
  return {
    Id: id,
    Name: name,
  };
}

function branches(): Branch[] {
  return [
    makeBranch(BranchCodes.GB, "United Kingdom"),
    makeBranch(BranchCodes.DE, "Germany"),
    makeBranch(BranchCodes.US, "United States of America"),
  ];
}
//#endregion

//#region Customer

type Address = {
  Id?: number;
  FirstName?: string | null;
  LastName?: string | null;
  Line1?: string | null;
  Line2?: string | null;
  Town?: string | null;
  PostCode?: string | null;
  County?: string | null;
  Country?: string | null;
  CountryId?: number;
  CountryCode?: string | null;
  PhoneNumber?: string | null;
  AddressHasBeenModified?: boolean;
  IsUkForVAT?: boolean;
  IsEu?: boolean;
  UseAsDefaultBillingAddress?: boolean;
  CompanyName?: string;
  CompanyRegistrationNo?: string;
  IsInvoiceAddress?: boolean;
  AddressOnOneLine?: string;
};

type Customer = {
  Id: number;
  UserBranchId: BranchCodes;
  Email?: string;
  FirstName?: string;
  LastName?: string;
  PhoneNumber?: string;
  MobileNumber?: string;
  RelayTopFolderNote?: string;
  RelaySubFolderNote?: string;
  IsMerchant?: boolean;
  IsApiEnabled?: boolean;
  HasTPSBook?: boolean;
  ShippingAddresses: Address[];
  ShippingAddress: Address;
  Notes: Note[];
};

function makeCustomer(): Customer {
  return {
    Id: 0,
    UserBranchId: BranchCodes.GB,
    ShippingAddresses: [],
    ShippingAddress: {} as Address,
    Notes: [],
  };
}
//#endregion

//-------------------- Vue Component ----------------------------------

export default defineComponent({
  name: "CustomerDetails",
  components: {
    CustomerSales,
    KButton,
    KButtonGroup,
    KTabStrip,
    GenericModalVue,
    OrdersBulkEditModal,
    BulkEditBranchModal,
    CustomerProducts,
    CustomerInvoices,
    "notes-list": NotesList,
  },
  setup() {
    const newCustomer = makeCustomer();
    const form: any = ref(null);
    const grid: any = ref(null);
    const customer: Ref<Customer> = ref(newCustomer);
    const note: any = ref("");
    const newUserBranchId: Ref<Branch | null> = ref(null);
    const selectedOrderIds: Ref<number[]> = ref([]);
    const showOrderBulkEditModal: Ref<boolean> = ref(false);
    const showBulkProductionBranchEdit: Ref<boolean> = ref(false);
    const showWarningModal: Ref<boolean> = ref(false);
    const warningModalText: Ref<string> = ref("");
    const isLoading: Ref<boolean> = ref<boolean>(false);

    return {
      form,
      grid,
      customer,
      note,
      branches: branches(),
      newUserBranchId,
      selectedOrderIds,
      showOrderBulkEditModal,
      showBulkProductionBranchEdit,
      showWarningModal,
      warningModalText,
      isLoading,
    };
  },
  data() {
    return {
      storageKey: `${this.$options.name}OrdersGridOptions`,
      gridHandle: "#gridCustomerOrders",
      shippingAddressId: 0,
      countries: [] as Country[],
      postingId: 0,
      message: "",
      msgClass: "",
      tabs: [
        {
          title: "CUSTOMER",
          content: "details",
        },
        {
          title: "ORDERS",
          content: "orders",
        },
        {
          title: "PRODUCTS",
          content: "products",
        },
        // TODO: uncomment when periodic invoices feature is ready.
        // {
        //   title: 'PERIODIC INVOICES',
        //   content: 'invoices'
        // },
        {
          title: "SALES",
          content: "sales",
        },
      ],
      selectedTab: 0,
    };
  },
  computed: {
    OrderNoteType() {
      return OrderNoteType;
    },
    newCustomer(): boolean {
      return this.customer.Id == 0;
    },
    notes() {
      const customerNotes: Note[] = this.customer.Notes;
      customerNotes.forEach(
        note => (note.CssClass = `${note.CssClass || "notes"} customer-note`),
      );

      return [...customerNotes];
    },
    isValidForm(): boolean {
      return (
        !!this.newUserBranchId &&
        !!this.customer.Email &&
        !!this.customer.FirstName &&
        !!this.customer.LastName
      );
    },
  },
  watch: {
    shippingAddressId(val) {
      if (val == 0) {
        this.customer.ShippingAddress = {
          Id: 0,
          FirstName: null,
          LastName: null,
          Line1: null,
          Line2: null,
          Town: null,
          PostCode: null,
          County: null,
          Country: null,
          CountryId: 0,
          CountryCode: null,
          PhoneNumber: null,
          AddressOnOneLine: ",",
          AddressHasBeenModified: false,
          IsUkForVAT: false,
          IsEu: false,
          UseAsDefaultBillingAddress: false,
        };
      }
      const a = this.customer.ShippingAddresses.find((o: any) => o.Id == val);
      if (a) this.customer.ShippingAddress = a;
    },
  },
  mounted() {
    ku.applyGridOptionDeferred(
      $,
      this.gridHandle,
      ku.unstashGridOptions(this.storageKey),
      500,
    );

    // eslint-disable-next-line
    const _this = this;
    const _window: any = window;
    _window.router = this.$router;
    _window.getCustomerId = function () {
      const url: string = _window.location.toString();
      const params = url.split("/");
      const customerId = params[params.length - 1];
      return { customerId };
    };

    _window.getOrderWithQuoteType = (OrderType: any, IsFinalised: boolean) =>
      !IsFinalised
        ? "<span style='color: #005580; text-decoration: underline;'>Quote</span>"
        : "<span>" + OrderType + "</span>";

    // An event on table selection change.
    _window.selectionChange = (e: any) => {
      this.selectedOrderIds = [];
      Array.from(e.sender.select()).forEach((element: any) => {
        const row = $(element);

        const type = row.find("td.row-type").text();

        if (type === "Quote") {
          row.removeClass("k-state-selected");
        } else {
          const id = row.find("td.row-id").text();

          const orderNumericId = Number(id);

          this.selectedOrderIds.push(orderNumericId);
        }
      });
    };

    // An event on table selection change.
    _window.dataLoaded = () => {
      const grid = $(_this.grid);

      const rows = grid.find("tr.k-master-row");

      rows.each((rowIndex: number) => {
        const row = rows[rowIndex];
        const rowElement = $(row);
        const type = rowElement.find("td.row-type").text();

        if (type === "Quote") {
          const checkbox = rowElement.find("input.k-checkbox");
          $(checkbox).remove();
        }
      });
    };

    if (!_window.getNotesFlag)
      _window.getNotesFlag = (
        NotesCount: number | null,
        AdvisoryNotesCount: number | null,
      ) => {
        if (AdvisoryNotesCount && AdvisoryNotesCount > 0) {
          return "<span class='k-icon k-i-exclamation-circle'></span>";
        }
        return "<span></span>";
      };

    this.loadCountries();

    if (this.$route.params.customerId != "0") {
      $.get(
        `/form/pms/getcustomer/${this.$route.params.customerId}?customerId=${this.$route.params.customerId}`,
        {},
        (data: any) => {
          this.customer = data;
          this.newUserBranchId =
            branches().find(b => b.Id === this.customer.UserBranchId) ?? null;
        },
      );
    }
  },
  methods: {
    onCloseBulkEditBranchModal() {
      $(this.gridHandle).data("kendoGrid").dataSource.read();
      this.selectedOrderIds = [];
      this.showBulkProductionBranchEdit = false;
    },
    loadCountries() {
      $.get("/form/grid/countries", {}, (data: Country[]) => {
        this.countries = data;
      });
    },
    validate() {
      const form: HTMLFormElement = this.form;
      form.classList.add("was-validated");
      return form.checkValidity();
    },
    clear(postingId: number) {
      if (postingId !== this.postingId) return;
      this.form.classList.remove("was-validated");
      this.message = "";
    },
    posting() {
      this.msgClass = "info";
      this.message = "The customer record is being updated...";
      return ++this.postingId;
    },
    success(postingId: number) {
      this.msgClass = "success";
      this.message = "The customer has been successfully updated";
      setTimeout(() => this.clear(postingId), 2500);
    },
    error(postingId: number) {
      this.msgClass = "danger";
      this.message = "The customer has not been updated";
      setTimeout(() => this.clear(postingId), 2500);
    },
    saveCustomer() {
      if (!this.validate()) return;
      const postingId = this.posting();
      if (this.newCustomer && !!this.customer?.ShippingAddress)
        this.customer.ShippingAddresses.push(this.customer.ShippingAddress);

      $.post("/form/pms/setCustomer", this.customer)
        .success((data: Customer) => {
          this.success(postingId);
          this.customer = data;
        })
        .error(() => this.error(postingId));
    },
    noteAdded(note: Note): void {
      if (!this.customer) {
        return;
      }

      this.customer.Notes.push(note);
    },
    noteUpdated(note: Note): void {
      if (!this.customer) {
        return;
      }

      if (this.customer.Notes.some((n: Note) => n.Id === note.Id)) {
        this.customer.Notes = this.customer.Notes.map((n: Note) =>
          n.Id !== note.Id ? n : note,
        );
      }
    },
    noteDeleted(noteId: number): void {
      if (!this.customer) {
        return;
      }

      this.customer.Notes = this.customer.Notes.filter(
        (note: Note) => note.Id !== noteId,
      );
    },
    getBranchName() {
      if (this.customer.UserBranchId == 1) return "UK";
      if (this.customer.UserBranchId == 2) return "Germany";
      if (this.customer.UserBranchId == 3) return "US";
    },
    onCloseOrderBulkEditModal(): void {
      // Reload data from API.
      $(this.gridHandle).data("kendoGrid").dataSource.read();

      this.selectedOrderIds = [];

      this.showOrderBulkEditModal = false;
    },
    onWarningModalClose(): void {
      this.showWarningModal = false;
    },
    async onSelectTab(e: any): Promise<void> {
      this.selectedTab = e.selected;

      if (this.selectedTab === 1) {
        const _window: any = window;

        this.isLoading = true;

        const customerOrdersPartialResponse = await axios.get(
          `/form/grid/CustomerOrdersPartial?customerId=${this.$route.params.customerId}`,
        );

        this.isLoading = false;

        let html = customerOrdersPartialResponse.data;

        html = html.replace(/\/grid\//gi, "/form/$&"); // fix up server url slug

        const grid = $(this.grid);

        grid.html(html);

        grid.on("click", "#bulkEditButton", (e: Event) => {
          e.preventDefault();

          if (this.selectedOrderIds.length === 0) {
            return;
          }

          const gridData = $(this.gridHandle)
            .data("kendoGrid")
            .dataSource.data();

          const unpaidStatuses = OrderStatuses.filter(
            status =>
              status.Id === OrderStatus.ProcessingPayment ||
              status.Id === OrderStatus.CardRequired ||
              status.Id === OrderStatus.PaymentFailed ||
              status.Id === OrderStatus.SuspendedOrder ||
              status.Id === OrderStatus.EmbryonicOrder,
          );

          const selectedOrderStatusIds: number[] = gridData
            .filter((data: any) => this.selectedOrderIds.includes(data.Id))
            .map((data: any) => data.OrderStatus);

          const allUnpaidOrderStatuses = selectedOrderStatusIds.every(id =>
            unpaidStatuses.some(status => status.Id === id),
          );

          const anyUnpaidOrderStatuses = selectedOrderStatusIds.some(id =>
            unpaidStatuses.some(status => status.Id === id),
          );

          if (anyUnpaidOrderStatuses && !allUnpaidOrderStatuses) {
            this.warningModalText = "Please select only paid or unpaid orders";
            this.showWarningModal = true;

            return;
          }

          this.showOrderBulkEditModal = true;
        });

        grid.on("click", "#bulkProductionBranchEdit", (e: Event) => {
          e.preventDefault();
          if (this.selectedOrderIds.length === 0) {
            return;
          }
          this.showBulkProductionBranchEdit = true;
        });

        grid.on("click", "td", async (e: Event) => {
          const el = e.target as Element;

          if (
            el.classList.contains("k-checkbox") ||
            Array.from(el.childNodes).find(
              (e: any) => e.classList && e.classList.contains("k-checkbox"),
            )
          ) {
            return;
          }

          const row = $(el).parents("tr");

          let id = row.find("td.row-id").text();

          const orderNumericId = Number(id);
          if (this.selectedOrderIds.includes(orderNumericId)) {
            this.selectedOrderIds = this.selectedOrderIds.filter(
              orderId => orderId !== orderNumericId,
            );
          } else {
            this.selectedOrderIds.push(orderNumericId);
          }

          let gotoPage = `/orders/${id}`;
          if ($(el).hasClass("email-field")) {
            if ($(el).hasClass("is-user")) {
              id = row.find(".user-id").text();
            } else if ($(el).hasClass("is-owner")) {
              id = row.find(".owner-id").text();
            }
            gotoPage = `/customers/${id}`;
          }
          const data = await this.$specialOrder.getOrderQuoteStatus(id);
          if (data && !data.IsFinalised) {
            gotoPage = `/edit-order/${id}`;
          }
          _window.router.push(gotoPage);
        });
      }
    },
  },
});
