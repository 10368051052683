
import PreventUnpaidStatusSelectModalResponse from "@/core/types/modal/preventUnpaidStatusSelectModalResponse";
import { DatePicker } from "@progress/kendo-vue-dateinputs";
import { DropDownList } from "@progress/kendo-vue-dropdowns";
import { RadioButton } from "@progress/kendo-vue-inputs";
import { defineComponent, Ref, ref } from "vue";
import { TextArea } from "@progress/kendo-vue-inputs";
export default defineComponent({
  name: "PreventUnpaidStatusSelectModalVue",
  components: {
    radiobutton: RadioButton,
    datepicker: DatePicker,
    dropdownlist: DropDownList,
    formtextarea: TextArea,
  },
  props: {
    show: Boolean,
    userIsForInvoice: Boolean,
    selectList: Array,
  },
  data() {
    const manualPaidOptions: any = ref([
      { Id: 1, Name: "Bank transfer" },
      { Id: 2, Name: "In house card payment terminal" },
      { Id: 3, Name: "Paypal" },
      { Id: 4, Name: "Manual Stripe charge" },
    ]);
    const unpaidStatusesModel: Ref<PreventUnpaidStatusSelectModalResponse> =
      ref({
        paidType: 1,
        paidDate: new Date(),
        manualPaymentMethod: null,
        save: false,
        user: null,
        note: "",
      });

    return {
      selectedValue: null,
      manualPaidOptions,
      unpaidStatusesModel,
    };
  },
  methods: {
    close(save: boolean) {
      (
        this.unpaidStatusesModel as PreventUnpaidStatusSelectModalResponse
      ).save = save;
      this.$emit("close", this.unpaidStatusesModel);
    },
    oBackDropClick(event: any): void {
      if (event.target.className === "modal-wrapper") this.close(false);
    },
    handlePaidTypeChange(e: any): void {
      (
        this.unpaidStatusesModel as PreventUnpaidStatusSelectModalResponse
      ).paidType = e.value;
    },
    handlePaymentMethodChange(e: any): void {
      (
        this.unpaidStatusesModel as PreventUnpaidStatusSelectModalResponse
      ).manualPaymentMethod = e.value;
    },
    handleUserChange(e: any): void {
      (
        this.unpaidStatusesModel as PreventUnpaidStatusSelectModalResponse
      ).user = e.value;
    },
    handlePaymentDateChange(e: any): void {
      (
        this.unpaidStatusesModel as PreventUnpaidStatusSelectModalResponse
      ).paidDate = e.target.value;
    },
    handleNoteChange(e: any) {
      (
        this.unpaidStatusesModel as PreventUnpaidStatusSelectModalResponse
      ).note = e.value;
    },
    onYesButtonClick(): void {
      this.close(true);
    },
  },
});
